<template>
    <AssetMarker
        :asset="asset"
        :is-selected="isSelected"
        :interactive="interactive"
        :visible="visible"
        :information-pills="informationPills"
        v-on="$listeners"
    />
</template>

<script>
import { measurementHelper } from '@/utils'
import AssetMarker from '@/components/AssetMarker'
import { fillLevelThresholds } from './dynamicBinSettings'

export default {
    name: 'DynamicBinMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        fillLevel() {
            return this.asset?.asset_details?.sensor_data?.fill_level?.value
        },
        fullnessClass() {
            return this.fillLevel <= fillLevelThresholds.low
                ? 'sensor-data-bin-empty'
                : this.fillLevel >= fillLevelThresholds.high
                ? 'sensor-data-bin-full'
                : 'sensor-data-bin-medium'
        },
        informationPills() {
            const pills = []

            if (typeof this.fillLevel === 'number') {
                pills.push({
                    text: measurementHelper.converters.fill_level(
                        this.fillLevel
                    ),
                    className: `sensor-data sensor-data-percentage ${this.fullnessClass}`,
                })
            }

            return pills
        },
    },
}
</script>
