<template>
    <LMarker
        :draggable="isDraggable"
        :lat-lng="latLng"
        :visible="visible"
        v-on="$listeners"
        @dragend="updateCoordinates"
    >
        <LTooltip :content="name" />
    </LMarker>
</template>

<script>
import { mapMutations } from 'vuex'
import { Icon } from 'leaflet'
import { LMarker, LTooltip } from 'vue2-leaflet'

Icon.Default.imagePath = '/img/leaflet/'

export default {
    name: 'DefaultMarker',
    components: {
        LMarker,
        LTooltip,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isDraggable() {
            return (
                this.$route.name === 'editAsset' &&
                this.$route.params.id == this.asset.id &&
                this.asset.asset_details?.static
            )
        },
        latLng() {
            return this.asset.asset_details?.position
                ? [
                      this.asset.asset_details.position.latitude,
                      this.asset.asset_details.position.longitude,
                  ]
                : [this.asset.position?.lat, this.asset.position?.lng]
        },
        name() {
            return this.asset.asset_details?.name ?? this.asset.name
        },
    },
    methods: {
        ...mapMutations('tracker', ['updateAsset']),
        updateCoordinates(marker) {
            this.updateAsset({
                id: this.asset.asset_details?.id ?? this.asset.id,
                position: {
                    latitude: marker.target._latlng.lat,
                    longitude: marker.target._latlng.lng,
                },
            })
        },
    },
}
</script>
