<template>
    <AssetMarker
        :asset="asset"
        :interactive="interactive"
        :is-on="isOn"
        :is-selected="isSelected"
        :visible="visible"
        v-on="$listeners"
    />
</template>

<script>
import AssetMarker from './AssetMarker'

export default {
    name: 'MachineMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isOn() {
            return this.asset.asset_details?.sensor_data?.d1?.value
        },
    },
}
</script>
